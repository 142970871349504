// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-doc-page-js": () => import("/opt/build/repo/src/templates/doc-page.js" /* webpackChunkName: "component---src-templates-doc-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-delete-js": () => import("/opt/build/repo/src/pages/account/delete.js" /* webpackChunkName: "component---src-pages-account-delete-js" */),
  "component---src-pages-account-expired-js": () => import("/opt/build/repo/src/pages/account/expired.js" /* webpackChunkName: "component---src-pages-account-expired-js" */),
  "component---src-pages-account-index-js": () => import("/opt/build/repo/src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-login-js": () => import("/opt/build/repo/src/pages/account/login.js" /* webpackChunkName: "component---src-pages-account-login-js" */),
  "component---src-pages-download-platforms-js": () => import("/opt/build/repo/src/pages/download-platforms.js" /* webpackChunkName: "component---src-pages-download-platforms-js" */),
  "component---src-pages-download-js": () => import("/opt/build/repo/src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-migrate-js": () => import("/opt/build/repo/src/pages/migrate.js" /* webpackChunkName: "component---src-pages-migrate-js" */),
  "component---src-pages-pricing-js": () => import("/opt/build/repo/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-release-notes-js": () => import("/opt/build/repo/src/pages/release-notes.js" /* webpackChunkName: "component---src-pages-release-notes-js" */),
  "component---src-pages-terms-and-privacy-js": () => import("/opt/build/repo/src/pages/terms-and-privacy.js" /* webpackChunkName: "component---src-pages-terms-and-privacy-js" */),
  "component---src-pages-terms-js": () => import("/opt/build/repo/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-unsubscribe-js": () => import("/opt/build/repo/src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */)
}

